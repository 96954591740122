export const FLOQ_MAIN = '#8341EF';

export const SECONDARY_LIGHT = '#F5F5F5';
export const SECONDARY_MEDIUM = '#F0F0F0';
export const SECONDARY_DARK = '#D3D3D3';

export const UI_GREY_1 = '#f5f5f5';
export const UI_GREY_2 = '#c4c4c4';
export const UI_GREY_3 = '#d9d9d9';
export const UI_GREY_4 = '#e2e2e2';
export const UI_GREY_5 = '#666666';
export const UI_BLACK = '#2e2e36';

export const COLOR_BLACK = 'rgba(0, 0, 0)';

export const COLOR_TEXT = 'rgba(0, 0, 0, 0.87)';
export const COLOR_TEXT_MEDIUM = 'rgba(0, 0, 0, 0.6)';
export const COLOR_TEXT_LIGHT = 'rgba(0, 0, 0, 0.54)';

export const COLOR_WHITE = '#ffffff';
export const COLOR_WHITE_10 = 'rgba(255, 255, 255, 0.1)';
export const COLOR_WHITE_16 = 'rgba(255, 255, 255, 0.16)';
export const COLOR_WHITE_40 = 'rgba(255, 255, 255, 0.4)';
export const COLOR_WHITE_50 = 'rgba(255, 255, 255, 0.5)';
export const COLOR_WHITE_60 = 'rgba(255, 255, 255, 0.6)';

export const COLOR_ERROR = '#EE1060';

export const COLOR_HOVER = 'rgba(0, 0, 0, 0.04)';
export const COLOR_SELECTED = 'rgba(243,240,254)';
export const COLOR_ERROR_SELECTED = 'rgba(255,244,248)';
export const COLOR_DISABLED = 'rgba(0, 0, 0, 0.38)';

export const COLOR_SUCCESS = '#0cb464';

export const getBlackOrWhiteCalculated = (c?: string) => {
    // Variables for red, green, blue values
    let r, g, b;
    let color: any = c;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
    } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
    }

    const hsp = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    if (hsp > 0.5) {
        return COLOR_TEXT;
    } else {
        return COLOR_WHITE;
    }
};
